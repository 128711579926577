import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { PDFDocument } from 'pdf-lib';
import { useNavigate } from 'react-router-dom';
import './PaymentGateway.css'; // Archivo CSS para estilos
import FooterEmail from './FooterEmail';

const PaymentGateway = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const navigate = useNavigate();
  const getUserEmail = () => localStorage.getItem('userEmail');
  const [email, setUserEmail] = useState(getUserEmail());

  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    setUserEmail(null);
    navigate('/');
  };

  useEffect(() => {
    if (!email) {
      console.error('User email not found. Please log in again.');
      setError('User email not found. Please log in again.');
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `https://oa5zd3x7jifog5lggl3nulovl40bkunf.lambda-url.us-east-1.on.aws/?email=${encodeURIComponent(email)}`
        );
        if (!response.ok) {
          throw new Error('Lambda request failed.');
        }

        const result = await response.json();

        if (Array.isArray(result) && result.length > 0) {
          // Si el resultado es un arreglo y tiene elementos, lo ordenamos
          setData(result.sort((a, b) => new Date(b.DownloadTime) - new Date(a.DownloadTime)));
        } else {
          // Si el resultado está vacío, mostramos un mensaje de "sin historial"
          setData([]);
          setError('****');
        }
      } catch (error) {
        console.error('Error during Lambda request:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [email]);

  const validateFile = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const pageCount = pdfDoc.getPageCount();

      if (pageCount > 20) {
        alert('The file exceeds the 20-page limit. Please select another file.');
        setNumPages(0);
        return false;
      }

      setNumPages(pageCount);
      return true;
    } catch (err) {
      console.error('Error reading PDF file:', err);
      alert('Could not read the PDF file. Please try another file.');
      return false;
    }
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const isValid = await validateFile(file);
      if (!isValid) {
        setSelectedFile(null);
      }
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || uploading) {
      return;
    }

    setUploading(true);

    AWS.config.update({
      accessKeyId: 'AKIA42J7OELPXKHF6YML',
      secretAccessKey: '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',
      region: 'us-east-1',
    });

    const s3 = new AWS.S3();
    const uploadFile = async (file, key) => {
      const params = {
        Bucket: 'pdfextracttools',
        Key: key,
        Body: file,
        ContentType: file.type,
      };

      try {
        await s3.upload(params).promise();
        return `https://s3.amazonaws.com/${params.Bucket}/${encodeURIComponent(key)}`;
      } catch (err) {
        console.error('Error uploading the file to S3:', err);
        alert('There was an error uploading the file.');
      }
    };

    const fileURL = await uploadFile(selectedFile, selectedFile.name);
    navigate('/extracttextpage', { state: { fileURL, numPages, email } });
    setUploading(false);
    setSelectedFile(null);
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <div className="page-container">
      <div className="logout-section">
        <button onClick={handleLogout} className="logout-button">
          Salir
        </button>
      </div>

      <div className="upload-section">
        <h2>Subir archivo para extracción de texto</h2>
        <input
          id="fileInput"
          type="file"
          accept=".pdf"
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          disabled={uploading}
        />
        <button className="big-upload-button" onClick={triggerFileInput} disabled={uploading}>
          {selectedFile ? selectedFile.name : 'Seleccionar Archivo'}
        </button>
        {selectedFile && <div className="file-preview">{selectedFile.name} ({numPages} pages)</div>}
        <br />
        <button onClick={handleUpload} disabled={uploading || !selectedFile || numPages === 0}>
          {uploading ? 'Cargando...' : 'Subir archivo'}
        </button>
      </div>

      <div className="history-section">
        {loading && <p>Cargando...</p>}
        {error && <p className="error">{error}</p>}
        {data && data.length > 0 ? (
          <div className="history-container">
            <h2>Historial de descarga:</h2>
            <ul className="history-list">
              {data.map((item, index) => (
                <li key={index}>
                  <strong>File:</strong> {item.FileKey}, <strong>Downloaded on:</strong> {new Date(item.DownloadTime).toLocaleString()}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          !loading && <p className="no-history-message">No se han realizado descargas todavía.</p>
        )}
      </div>
      <FooterEmail />
    </div>
  );
};

export default PaymentGateway;