// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider } from './AppContext'; // Importa el nuevo contexto
import RecargarSaldo from './components/RecargarSaldo';
import Login from './components/Login';
import Register from './components/Register';
import PaymentGateway from './components/PaymentGateway';
import Salida from './components/Salida';
import Verification from './components/verification';
import HomePage from './components/HomePage';
import ExtractTextPage from './components/ExtractTextPage';
import DownloadTextPage from './components/DownloadTextPage';
import ForgotPassword from './components/ForgotPassword';

import ReactGA from 'react-ga';

ReactGA.initialize('G-B5TGW9H63E');

ReactDOM.render(
  <Router>
    <AppProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/extracttextpage" element={<ExtractTextPage />} />
        <Route path="/downloadtextpage" element={<DownloadTextPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/recargar" element={<RecargarSaldo />} />
        <Route path="/paymentgateway" element={<PaymentGateway />} />
        <Route path="/salida" element={<Salida />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route index element={<HomePage />} />
      </Routes>
    </AppProvider>
  </Router>,
  document.getElementById('root')
);

