import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const validatePassword = (password) => {
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);

    return hasNumber && hasSpecialChar && hasUpperCase && hasLowerCase;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear previous error messages
    setRegistrationSuccess(false); // Reset success message

    if (!validatePassword(password)) {
      setErrorMessage('La contraseña debe tener mínimo 8 carácteres y contener al menos: 1 número, 1 carácter especial, una letra mayúscula, una letra minúscula.');
      return;
    }

    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // Otros atributos adicionales como nombre, número de teléfono, etc., se pueden agregar aquí
        },
      });
      setRegistrationSuccess(true); // Handle successful registration
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <h2>Registro</h2>
      {registrationSuccess ? (
        <div style={{ color: 'green' }}>
          El registro ha sido exitoso. Por favor, verifica tu correo electrónico para completar el proceso.
        </div>
      ) : (
        <form onSubmit={handleRegister}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Contraseña"
            required
          />
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <button type="submit">Registrarse</button>
        </form>
      )}
    </div>
  );
};

export default Register;
