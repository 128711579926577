import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports.js';
import { Amplify } from 'aws-amplify';
import { Link } from 'react-router-dom';

Amplify.configure(awsconfig);

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { fileURL, numPages } = state || {};

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await Auth.signIn(email, password);
      console.log('Login successful');

      // Almacena el email en localStorage después del login exitoso
      localStorage.setItem('userEmail', email);

      // Redirige al usuario a la página de extracción de texto o al historial de pagos
      if (fileURL) {
        navigate('/extracttextpage', { state: { email, fileURL, numPages } });
      } else {
        navigate('/PaymentGateway', { state: { email } });
      }
    } catch (error) {
      console.error('Login error', error);
      setError(error.message);
    }
  };

  return (
    <div className="container">
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="button">
          Iniciar sesión
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      <br />
      <br />
      <p>
        ¿No tienes una cuenta? <Link to="/register">Regístrate</Link>
      </p>
      <p>
        ¿Olvidaste tu contraseña? <Link to="/forgot-password">Restablécela aquí</Link>
      </p>
    </div>
  );
}

export default Login;